window.addEventListener("DOMContentLoaded", () => {
	declare_event_on_filter_projets();
	declare_event_on_loadmore_projets();
	declare_swiper_projets();
	declare_swiper_annees_projets();
});

function declare_event_on_filter_projets(container) {
	if (container) {
		const filter_category = container.querySelectorAll('.filters_category')[0];
		const is_slider = (container.querySelectorAll('.wp-block-starter-latest-projets .swiper:not(.annees-swiper)')[0]) ? true : false;
		let filters = filter_category.querySelectorAll('a');
		filters.forEach((filter) => {
			let cat_id = filter.getAttribute("data-id");
			filter.addEventListener('click', function () {
				if (filter.classList.contains('actif')) {
					filter_by_cat_projet("", container, is_slider);
					filter.classList.remove('actif')
				} else {
					filter_by_cat_projet(cat_id, container, is_slider);

					filters.forEach((filter) => {
						filter.classList.remove('actif');
					})
					filter.classList.add('actif');
				}
			})
		});
	} else {
		const filters_category = document.querySelectorAll('.wp-block-starter-latest-projets .filters_category');
		const is_slider = (document.querySelectorAll('.wp-block-starter-latest-projets .swiper:not(.annees-swiper)')[0]) ? true : false;
		if (filters_category.length != 0) {
			let filters = filters_category[0].querySelectorAll('a');
			filters.forEach((filter) => {

				let cat_id = filter.getAttribute("data-id");

				filter.addEventListener('click', function () {

					const container = filters_category[0].closest('.wp-block-starter-latest-projets');
					if (filter.classList.contains('actif')) {
						filter_by_cat_projet("", container, is_slider);
						filter.classList.remove('actif')
					} else {
						filter_by_cat_projet(cat_id, container, is_slider);

						filters.forEach((filter) => {
							filter.classList.remove('actif');
						})
						filter.classList.add('actif');
					}
				})
			});
		}
	}
}

function filter_by_cat_projet(cat_id, container, is_slider) {
	if (is_slider) {
		let posts_per_page = container.getAttribute('data-post_per_page');
		let excerpt_length = container.getAttribute('data-excerpt_length');
		let order = container.getAttribute('data-order');
		let orderby = container.getAttribute('data-orderby');
		let type = container.getAttribute('data-type');

		container.setAttribute('data-cat', cat_id);
		container.style.opacity = "0.6";

		let _data = {
			action: 'filter_cat_projets',
			posts_per_page: posts_per_page,
			excerpt_length: excerpt_length,
			cat: cat_id,
			order: order,
			orderby: orderby,
			type: type,
		};

		fetch(ajaxurl, {
			method: 'POST',
			body: (new URLSearchParams(_data)).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (response) {

			if (response.ok) {
				response.text().then(function (response) {

					container.querySelectorAll('.swiper:not(.annees-swiper) .swiper-wrapper')[0].innerHTML = response;

					container.style.opacity = "1";

					declare_swiper_projets();

					//On remet le offset par défaut
					let posts_per_page = container.getAttribute('data-post_per_page');
					container.setAttribute('data-offset', posts_per_page);

				});
			} else {
				container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
			}
		});

	} else {
		let posts_per_page = container.getAttribute('data-post_per_page');
		let excerpt_length = container.getAttribute('data-excerpt_length');
		let order = container.getAttribute('data-order');
		let orderby = container.getAttribute('data-orderby');
		let type = container.getAttribute('data-type');
		//Pagination ou loadmore ou rien
		let loading = container.getAttribute('data-loading');

		let clean_url = window.location.href.replace(/\/page.*$/, "/");

		container.setAttribute('data-cat', cat_id);
		container.style.opacity = "0.6";

		let _data = {
			action: 'filter_cat_projets',
			posts_per_page: posts_per_page,
			url: clean_url,
			excerpt_length: excerpt_length,
			cat: cat_id,
			loading: loading,
			order: order,
			orderby: orderby,
			type: type,
		};

		fetch(ajaxurl, {
			method: 'POST',
			body: (new URLSearchParams(_data)).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (response) {

			if (response.ok) {
				response.text().then(function (response) {
					container.innerHTML = response;
					declare_event_on_filter_projets(container);
					declare_event_on_loadmore_projets(container);
					container.style.opacity = "1";
					let posts_per_page = container.getAttribute('data-post_per_page');
					container.setAttribute('data-offset', posts_per_page);
					declare_swiper_annees_projets();
				});
			} else {
				container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
			}
		})
	}
}

function declare_event_on_loadmore_projets(container) {
	const loadmores = document.querySelectorAll('.wp-block-starter-latest-projets .loadmore');
	if (loadmores.length != 0) {
		if (loadmores.length > 1) {
			loadmores.forEach((loadmore) => {
				const container = loadmore.closest('.wp-block-starter-latest-projets');
				loadmore.addEventListener('click', function () {
					loadmore_projets(loadmore, container);
				});
			});
		} else {
			const container = loadmores[0].closest('.wp-block-starter-latest-projets');
			loadmores[0].addEventListener('click', function () {
				loadmore_projets(loadmores[0], container);
			});
		}
	}
}

function loadmore_projets(loadmore, container) {

	let posts_per_page = container.getAttribute('data-post_per_page');
	let offset = container.getAttribute('data-offset');
	let order = container.getAttribute('data-order');
	let orderby = container.getAttribute('data-orderby');
	let cat = "";

	if (container.querySelectorAll('.filters_category')[0]) cat = container.querySelectorAll('.filters_category')[0].getAttribute('data-cat');
	let post_count = (cat) ? container.querySelectorAll('.filters_category')[0].getAttribute('data-post_count') : container.getAttribute('data-post_count');

	container.querySelectorAll('li.loadmore');
	container.style.opacity = "0.6";

	let _data = {
		action: 'loadmore_projets',
		posts_per_page: posts_per_page,
		offset: offset,
		order: order,
		orderby: orderby,
		cat: cat,
	};

	fetch(ajaxurl, {
		method: 'POST',
		body: (new URLSearchParams(_data)).toString(),
		headers: {
			'Content-type': 'application/x-www-form-urlencoded'
		}
	}).then(function (response) {
		if (response.ok) {
			response.text().then(function (response) {
				loadmore.insertAdjacentHTML('beforebegin', response);
				container.style.opacity = "1";
				let new_offset = parseInt(offset) + parseInt(posts_per_page);
				container.setAttribute("data-offset", new_offset);
				if (parseInt(new_offset) >= parseInt(post_count)) loadmore.style.display = "none";
			});
		} else {
			container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
		}
	})
}

function declare_swiper_projets() {
	let latest_projets = document.querySelectorAll('.wp-block-starter-latest-projets');
	if (latest_projets.length != 0) {
		if (latest_projets.length > 1) {
			latest_projets.forEach((item) => {
				let swiper_item = "";
				if (item.querySelectorAll('.swiper:not(.swiper-annees)').length != 0) {
					swiper_item = item.querySelectorAll('.swiper:not(.swiper-annees)')[0];
					let swiper_name = ".swiper_" + swiper_item.getAttribute('data-id');
					let nb_slide = parseInt(swiper_item.getAttribute('data-nb-slide'));
					let breakpoints = {
						0: {
							slidesPerView: 1,
						},
						577: {
							slidesPerView: 2,
						},
						767: {
							slidesPerView: 3,
						},
						992: {
							slidesPerView: nb_slide,
						},
					};
					if (nb_slide == 1) {
						breakpoints = {
							0: {
								slidesPerView: nb_slide,
							},
						};
					}
					new Swiper(swiper_name, {
						// watchSlidesProgress: true,
						spaceBetween: 30,
						breakpoints: breakpoints,
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						}
					});
				}
			});
		} else if (latest_projets[0].querySelectorAll('.swiper:not(.swiper-annees)').length != 0) {
			let item = latest_projets[0].querySelectorAll('.swiper:not(.swiper-annees)')[0];
			let swiper_name = ".swiper_" + item.getAttribute('data-id');
			let nb_slide = item.getAttribute('data-nb-slide');
			let breakpoints = {
				0: {
					slidesPerView: 1,
				},
				577: {
					slidesPerView: 2,
				},
				767: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: nb_slide,
				},
			};
			if (nb_slide == 1) {
				breakpoints = {
					0: {
						slidesPerView: nb_slide,
					},
				};
			}
			new Swiper(swiper_name, {
				// watchSlidesProgress: true,
				spaceBetween: 30,
				breakpoints: breakpoints,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			});
		}
	}
}

function declare_swiper_annees_projets() {
	new Swiper('.annees-swiper', {
		loop: false,
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			nextEl: '.swiper-button-prev-annees',
			prevEl: '.swiper-button-next-annees',
		},
		breakpoints: {
			0: {
				slidesPerView: 3,
			},
			540: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 7,
			},
			1024: {
				slidesPerView: 9,
			},
			1200: {
				slidesPerView: 12,
			},
		},
	});
	var anneeSwiper = document.querySelectorAll('.annees-swiper .swiper-wrapper')[0];
	if (anneeSwiper) anneeSwiper.style.transform = 'translate3d(0px, 0,0)';
}
